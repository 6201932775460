import { createStore } from 'vuex'
import { getHomePageModularList, getUserInfo, outLogin } from '@/api'

import router from '@/router'
export default createStore({
  state: {
    navList: [],
    swiperIndex: 0,
    userInfo: null,
    login: false,
    systemInfo:{}
  },
  getters: {
  },
  mutations: {
    SET_LIST (state, data) {
      state.navList = data
    },
    SET_SWIPER_INDEX (state, data) {
      state.swiperIndex = data
    },
    SET_USER_INFO (state, data) {
      state.userInfo = data
    },
    SET_LOGIN (state, data) {
      state.login = data
    },
    SET_SYSTEM_INFO (state, data) {
      document.title = data.title
      state.systemInfo = data
    }
  },
  actions: {
    async getNavList ({ commit }) {
      try {
        const res = await getHomePageModularList({ul:window.location.host})
        commit('SET_LIST', res.obj.modularList)
      } catch (e) {
        console.log(e)
      }
    },
    async getUserInfo ({ commit }) {
      try {
        const res = await getUserInfo()
        commit('SET_USER_INFO', res.obj)
        commit('SET_LOGIN', true)
      } catch (e) {
        console.log(e)
        commit('SET_USER_INFO', null)
        commit('SET_LOGIN', false)
      }
    },
    async OUT_LOGIN ({ commit }) {
      try {
        await outLogin()
        commit('SET_USER_INFO', null)
        commit('SET_LOGIN', false)
        localStorage.clear()
        router.push({
          path: '/'
        })
      } catch (e) {
        console.log(e)
      }
    }

  },
  modules: {
  }
})
