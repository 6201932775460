import http from './request'
// 专业智能版
export const sendTelPin = (params) => {
  return http.fetchPost('/api/nt/login/sendTelPin', params)
}
export const pinLogin = (params) => {
  return http.fetchPost('/api/nt/login/pinLogin', params)
}
export const login = (params) => {
  return http.fetchPost('/api/nt/login/login', params)
}
export const getHomePageModularList = (params) => {
  return http.fetchPost('/api/nt/baiquan/modular/getHomePageModularList', params)
}
export const getUserInfo = (params) => {
  return http.fetchPost('/api/ut/user/getUserInfo', params)
}
export const outLogin = (params) => {
  return http.fetchPost('/api/ut/user/outLogin', params)
}
export const getCityOrDistrict = (params) => {
  return http.fetchPost('/api/nt/baiquan/getCityOrDistrict', params)
}

export const getRegisterField = (params) => {
  return http.fetchPost('/api/nt/baiquan/getRegisterField', params)
}

// 注册-获取学校
export const getMiddleSchool = (params) => {
  return http.fetchPost('/api/nt/login/getMiddleSchool', params)
}

export const register = (params) => {
  return http.fetchPost('/api/nt/baiquan/register', params)
}

export const forgetPassword = (params) => {
  return http.fetchPost('/api/nt/login/forgetPassword', params)
}
export const getLogoByUrl = (params) => {
  return http.fetchPost('/api/nt/common/getLogoByUrl', params)
}
