<template>
  <div class='base-footer flex-between' v-if="showHeader">
    <p>主办单位：拜泉县教育局 ｜ 技术支持：安徽萃文科技有限责任公司 ｜ <a
        href="https://beian.miit.gov.cn/?spm=5176.12825654.7y9jhqsfz.110.3dbd2c4atrq64K&aly_as=AYjzBIfS#/Integrated/index"
        target="_blank"> 皖ICP备2023001399号</a></p>
    <p>如果你在使用过程中有任何问题，请联系我们：15904355916</p>
  </div>
</template>

<script>

export default {
  components: {},
  data() {
    return {

    }
  },
  
  methods: {

  },
  created() {

  },
  mounted() {

  }
}
</script>
<style lang='scss' scoped>
.base-footer {
  background: #1D1D1D;
  padding: 12px 60px;
  position: fixed;
  bottom: 0;
  z-index: 5;
  left: 0;
  right: 0;

  p {
    font-size: 12px;
    font-family: MiSans, MiSans-Regular;
    font-weight: 400;
    text-align: LEFT;
    color: #fff8f8;
    line-height: 12px;
  }

  a {
    color: #fff;
    text-decoration: none;
  }
}</style>
