/* eslint-disable prefer-promise-reject-errors */
/*
 * @Descripttion:
 * @version:
 * @Author:
 * @Date: 2021-03-09 17:54:35
 * @LastEditors: jjx 1432652396@qq.com
 * @LastEditTime: 2022-12-05 11:28:59
 */
import axios from 'axios'
import router from '@/router/index.js'
import {
  ElMessage, ElMessageBox
} from 'element-plus'
// const router = useRouter()
axios.defaults.timeout = 100000
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8'

axios.defaults.baseURL = process.env.VUE_APP_BASE_API

// 请求拦截器
axios.interceptors.request.use(config => {
  config.headers.tk = localStorage.getItem('token') || ''
  config.headers.na = Math.random() * 100 // 加密不传
  config.headers.ts = JSON.stringify({
    ts: new Date().getTime()
  })
  return config
}, err => {
  ElMessage.warning(err)
  return Promise.reject(err)
})
// 响应拦截器
axios.interceptors.response.use(response => {
  if (response.data.code !== '00000') {
    if (response.data.code === 'A0230') {
      router.push({
        path: '/login'
      })
      localStorage.clear()
      return Promise.reject(response.data.msg)
    } else if (response.data.code === 'A0240') {
      // 账户重复登录
      ElMessageBox.alert('您的账号已在其他设备登录，如非本人操作，请及时进行密码修改。', '', {
        confirmButtonText: '确认',
        customClass: 'refusedUser',
        callback: () => {
          if (!response.config.saveTk) {
            localStorage.removeItem('token')
          }
          router.push({
            path: '/login'
          })
        }
      })
      return
    } else if (response.data.code === 'B0001') {
      ElMessage.warning(response.data.msg)
    } else {
      ElMessage.warning(response.data.msg)
      return Promise.reject(response.data.msg)
    }
  }

  return response
}, err => {
  if (err.response && err.response.status === 509) {
    const html = err.response.data
    const verifyWindow = window.open('', '_blank', 'height=400,width=560')
    verifyWindow.document.write(html)
    verifyWindow.document.getElementById('baseUrl').value = 'http://192.168.110.222:8443'
  } else {
    return Promise.reject('网络错误')
  }
})
export default {
  fetchPost (url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      axios.post(url, params, config).then(res => {
        resolve(res.data)
      }).catch(error => {
        reject(error)
      })
    })
  }
}
