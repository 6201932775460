import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import startQiankun from "./qiankun/index";
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.mount('#app')



const whiteList = ['/', '/login', '/register', '/agreement', '/findPwd']
router.beforeEach(async (to, from, next) => {
  if (!localStorage.getItem('token')) {
    if (whiteList.indexOf(to.path) > -1) {
      next()
    } else {
      next('/login')
    }
  } else {
    if (store.state.userInfo && store.state.userInfo.user.name) {
      if (to.path === '/login') {
        next('/')
      } else {
        next()
      }
    } else {
      await store.dispatch('getUserInfo')
      next()
    }
  }
})
startQiankun()
